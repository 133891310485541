import React, { useState, useEffect, useRef } from 'react';

const Home = () => {
  // states
  const [displayedText, setDisplayedText] = useState('');
  const [currentIntervalId, setCurrentIntervalId] = useState(null);
  // eslint-disable-next-line
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const buttonFRRef = useRef();
  const buttonENRef = useRef();
  const buttonNORef = useRef();

  let translations = [
    {lang: "en", text: "Hello there, welcome to my portfolio!"},
    {lang: "no", text: "Hei og velkomen til min portfolio!"},
    {lang: "fr", text: "Bonjour et bienvenue sur mon portfolio !"}
  ];


  const startAnimation = (text) => {
    let charIndex = 0;

    const intervalId = setInterval(() => {
      
      setDisplayedText(text.slice(0, charIndex));
      charIndex++;

      buttonFRRef.current?.classList?.add('disable');
      buttonENRef.current?.classList?.add('disable');
      buttonNORef.current?.classList?.add('disable');

      if(charIndex > text.length) {
        clearInterval(intervalId);
        // console.log('finished')

        setCurrentIntervalId(null);
        buttonFRRef.current?.classList?.remove('disable');
        buttonENRef.current?.classList?.remove('disable');
        buttonNORef.current?.classList?.remove('disable');

        return;
      } 
    }, 100)

    setCurrentIntervalId(intervalId);
    return intervalId;
  }

  
  useEffect(() => {
    const defaultLanguage = 'en';
    const defaultTranslation = translations.find((translation) => translation.lang === defaultLanguage);
    startAnimation(defaultTranslation.text);

    // clear effect
    return () => {
      if (currentIntervalId) {
        clearInterval(currentIntervalId);
      }
    };

  
  // eslint-disable-next-line
  }, [])

  const changeLanguage = (lang) => {
    const matchedLang = translations.find((translation) => translation.lang === lang);

    if (matchedLang && !currentIntervalId) {
      setDisplayedText('');
      setCurrentLanguage(lang);
      startAnimation(matchedLang.text);
    }
    
  }

  // render
  return (
    <main className="home flex-column center">

      <div className='browser-window'>
        <div className='top-bar'>
          <div className='circle circle-red'></div>
          <div className='circle circle-yellow'></div>
          <div className='circle circle-green'></div>
        </div>

        <div className='content flex'>
          <span className="typing-effect">{displayedText}<span className="cursor">
          </span></span>
        </div>
      </div>
      <div className='terminal-btns w-full flex-row center'>
        <button
          title="French"
          aria-label="Change the terminal language to French"
          ref={buttonFRRef}
          onClick={() => changeLanguage('fr')}
        >
          fr
        </button>
        <button
          title="English"
          aria-label="Change the terminal language to English"
          ref={buttonENRef}
          onClick={() => changeLanguage('en')}
        >
          en
        </button>
        <button
          title="Norwegian"
          aria-label="Change the terminal language to Norwegian"
          ref={buttonNORef}
          onClick={() => changeLanguage('no')}
        >
          no
        </button>
      </div>

    </main>
  );
};

export default Home;
