const Validation = () => {
    return (
        <div className="validation-message-wrapper flex-column center">
            <div className="validation-message flex-column center">
                <p> Email sent successfully!
                <i className="fa-solid fa-check"></i>
                </p>
            </div>
        </div>
    )
}

export default Validation;