import { useState, useRef } from 'react';
import Error from '../utils/Error';
import { validateEmail } from '../utils/validateEmail';
import Validation from '../utils/Validation';

const Contact = () => {
  const formRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  
  const handleInputChange = (event) => {
    const { name } = event.target;
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null // set to null to remove the error message
    }));
    console.log(errors);
    console.log(name);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

      // Field validation
    const name = event.target.name.value.trim();
    const email = event.target.email.value.trim();
    const message = event.target.message.value.trim();

    const newErrors = {};

    if (!name) {
      newErrors.name = 'Please enter your name';
    }
    if (!email) {
      newErrors.email = 'Please enter your email address';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!message) {
      newErrors.message = 'Please enter your message';
    }

    // Update errors Object
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log(newErrors);
      return;
    }

    // fetch contact form
    fetch("https://formsubmit.co/ajax/221f2c7830e7452aa990b5475b4dc537", {
      method: "POST",
      headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        email: email,
        message: message,
      })
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.log(error));

    formRef.current.reset();
    setSubmitted(true);
  }

    return (
      <main className="flex-column center contact-form-section">
        <h2>Contact</h2>
        <p>Feel free to contact me by using the email form below!</p>
        {submitted ? (
          <Validation />
        ) : ( 
          <div className="contact-form-wrapper">
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              action="https://formsubmit.co/ajax/221f2c7830e7452aa990b5475b4dc537"
              method="POST"
              className="contact-form flex-column center"
            >

              <label className="flex-column center">Name:
                <input
                  name="name"
                  type="text" 
                  onChange={handleInputChange}
                />
                {errors.name && <Error displayMessage={errors.name} />}
              </label>

              <label className="flex-column center">Email:
                <input
                  name="email" 
                  type="email"
                  onChange={handleInputChange}
                />
                {errors.email && <Error displayMessage={errors.email} />}
              </label>

              <label className="flex-column center">Your message:
                <textarea
                  name="message"
                  cols="30"
                  rows="10"
                  onChange={handleInputChange} 
                ></textarea>
                {errors.message && <Error displayMessage={errors.message} />}
              </label>

              <input
                type="text"
                name="_honey"
                className="honey-input"
              />

            <div className="btn-wrapper flex-row center">
                <button
                  type="submit"
                  className="main-neon-btn"
                >
                  Submit
                </button>
            </div>
            </form>
          </div>
        )}
        
      </main>
    );
  }
  
  export default Contact;

  /**
   * https://formsubmit.co/ajax/221f2c7830e7452aa990b5475b4dc537
   */