import profilPic from '../img/profil.webp';
import reactLogo from '../img/tech/react.png';
import mongoDbLogo from '../img/tech/mongodb.svg';
import laravelLogo from '../img/tech/laravel.svg';
import mySqlLogo from '../img/tech/mysql.svg';
import sassLogo from '../img/tech/sass.svg';
import expressLogo from '../img/tech/express.png';

const About = () => {


    // render
    return (
      <main className="about-page flex-column center">
        <h2>About</h2>

        <div className='about-card'>
        <div className='top-bar'>
          <div className='circle circle-red'></div>
          <div className='circle circle-yellow'></div>
          <div className='circle circle-green'></div>
        </div>
          <div className="bio-section-wrapper">
            <div className="img-card">
              <img src={profilPic} alt="Marie Occelli" width={220} height={220} />
            </div>
            <div className="bio-text">
                <p>Hello there! I'm Marie and I am a <strong>web developer</strong>.</p>
                <p>In 2022, I discovered <strong>web development</strong> and it became a new passion. I started learning the basis of web development by myself, and a few months later, I was starting a <strong>bootcamp in web development</strong> at the <strong>3W Academy</strong> in France. During that time, I learned buildings apps with <strong>Express.js</strong> and <strong>React.js</strong>.</p>
                <p>Over the past year, I had the opportunity to work intensively with <strong>Laravel</strong> in a professional setting during a one-year internship, which gave me a solid foundation in <strong>back-end</strong> development.</p>
                <p> I'm excited to continue growing in this field and to bring my skills to new and challenging projects.</p>
            </div>
          </div>

          <div className="skills-section-wrapper">
            <p>Here you find all the technologies that I practice and work with:</p>
            <div className="tech-wrapper">
              <img src={reactLogo} alt="React.js" width={70} height={70}/>
              <img src={expressLogo} alt="Express.js" width={70} height={70}/>
              <img src={mongoDbLogo} alt="MongoDB" width={70} height={70}/>
              <img src={sassLogo} alt="SASS" width={70} height={70}/>
              <img src={laravelLogo} alt="Laravel" width={70} height={70}/>
              <img src={mySqlLogo} alt="MySQL" width={70} height={70}/>
            </div>
          </div>
        </div>

      </main>
    );
  }
  
  export default About;