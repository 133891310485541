import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';


const App = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); 
  const [path, setPath] = useState(window.location.pathname);


 
  useEffect(() => {
    // close sidemenu when click is outside
    const closeSideMenu = (event) => {
      const burger = document.querySelector('.burger');
      const mobileNavbarWrapper = document.querySelector('.mobile-navbar-wrapper');

      if (mobileNavbarWrapper && burger) {
        if (!mobileNavbarWrapper.contains(event.target) && !burger.contains(event.target)) {
          mobileNavbarWrapper.classList.remove('active');
          mobileNavbarWrapper.classList.add('hidden');

          burger.classList.remove('active');
          setIsMobileMenuOpen(false);
        }
      }
    }

    document.body.addEventListener('click', closeSideMenu);
    return () => {
      document.body.removeEventListener('click', closeSideMenu);
    }
  }, [])


  const toggle = () => {
    const burger = document.querySelector('.burger');
    const mobileNavbarWrapper = document.querySelector('.mobile-navbar-wrapper');

    const isMobileMenuCurrentlyOpen = isMobileMenuOpen;
    burger.classList.toggle('active');

    if (!isMobileMenuCurrentlyOpen) {
      mobileNavbarWrapper.classList.add('active');
      mobileNavbarWrapper.classList.remove('hidden');
      setIsMobileMenuOpen(true);
    } else {
      mobileNavbarWrapper.classList.remove('active');
      mobileNavbarWrapper.classList.add('hidden');
      setIsMobileMenuOpen(false);
    }
  }
  
  return (
    <>
      <BrowserRouter>
        <header className="header flex-row space-between">
          <div className="title-wrapper">
            <Link className="title" onClick={() => setPath('/')} to="/">Marie OCCELLI</Link>
            <p>
              Web developer
            </p>
          </div>
          <div className="navbars-wrapper">
            <button className="burger hidden" onClick={toggle} title='Open mobile menu'>
              <span></span>
            </button>

            <div className="mobile-navbar-wrapper hidden">
              <nav className="mobile-navbar">
                <ul className='w-full'>
                  <li>
                    <Link className={`neon-button ${path === "/" ? "active" : ""}`} onClick={() => {setPath('/'); toggle()}} to="/">Home</Link>
                  </li>
                  <li>
                    <Link className={`neon-button ${path === "/about" ? "active" : ""}`} onClick={() => {setPath('/about'); toggle()}} to="/about">About</Link>
                  </li>
                  <li>
                    <Link className={`neon-button ${path === "/projects" ? "active" : ""}`} onClick={() => {setPath('/projects'); toggle()}} to="/projects">Projects</Link>
                  </li>
                  <li>
                    <Link className={`neon-button ${path === "/contact" ? "active" : ""}`} onClick={() => {setPath('/contact'); toggle()}} to="/contact">Contact</Link>
                  </li>
                </ul>
                {/**
                <div className='w-full'>
                  <ul className='lang-btn-wrapper w-full flex flex-row center'>
                    <li>
                      <button
                        className=''
                        onClick={() => {}}
                      >
                        FR
                      </button>
                    </li>
                    <li>
                      <button
                        className=''
                        onClick={() => {}}
                      >
                        EN
                      </button>
                    </li>
                    <li>
                      <button
                        className=''
                        onClick={() => {}}
                      >
                        NO
                      </button>
                    </li>
                  </ul>
                </div>
                */}

              </nav>
            </div>

            <nav className="desktop-navbar">
              <Link className={`neon-button ${path === "/" ? "active" : ""}`} onClick={() => setPath('/')} to="/">Home</Link>
              <Link className={`neon-button ${path === "/about" ? "active" : ""}`} onClick={() => setPath('/about')} to="/about">About</Link>
              <Link className={`neon-button ${path === "/projects" ? "active" : ""}`} onClick={() => setPath('/projects')} to="/projects">Projects</Link>
              <Link className={`neon-button ${path === "/contact" ? "active" : ""}`} onClick={() => setPath('/contact')} to="/contact">Contact</Link>
            </nav>
          </div>
        </header>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <footer>
        <div className="social-medias-wrapper">
          <div className="social-medias-content">
            <a href="https://linkedin.com/in/marie-occelli" target="_blank" rel="noreferrer" aria-label="My LinkedIn account" title="My LinkedIn account">
              <i className="fa-brands fa-linkedin-in neon-blue"></i>
            </a>
            <a href="https://github.com/marieoc" target="_blank" rel="noreferrer" aria-label="My github account" title="My github account">
              <i className="fa-brands fa-github neon-blue"></i>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
