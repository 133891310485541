
const Error = ({ displayMessage }) => {

    return (
        <div className="error-message" >
            <p>{displayMessage}</p>
        </div>
    )
}

export default Error;